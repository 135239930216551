<template>
  <div
    class="brand-item"
    :disabled="loading"
    @click="$emit('item-click')"
    :class="{ selected: brand.IsSelected }"
  >
    <div class="brand-item__info">
      <h3>{{ brand.brands }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandItem",
  components: {},
  props: {
    brand: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.brand-item {
  padding: 20px 12px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 0.3s ease all;
  height: 100%;
  &:hover {
    border-color: var(--red);
  }
  &.selected {
    border-color: var(--red);
    box-shadow: 0 0 0 3px var(--red);
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex: 1;
    h3 {
      color: var(--dark);
      font-size: 16px;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.32px;
      flex: 1;
      margin-bottom: 0;
    }
  }
}
</style>
